import { Auth } from 'aws-amplify'
import { Dropdown, NavItem, NavLink } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useAppRoot } from './RootContext'
import { GenericIcon, MembersIcon } from '../utils/Icons'

export const UserDropdown = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const appRoot = useAppRoot()
    const { username, auth_type } = appRoot

    const className = 'video-toolbar-hamburger-menu-item'
    const downloadIconClassName = 'sl-download-full-video-icon fa-fw'

    const handleSignOut = () =>
        confirmAlert({
            title: t('Sign out'),
            message: t(
                'Are you sure you want to sign out? You will not be able to sign in unless you are connected to the internet.'
            ),
            buttons: [
                {
                    label: t('Cancel'),
                    onClick: () => {}
                },
                {
                    label: t('Sign out'),
                    onClick: async () => {
                        if (auth_type === 'cognito') {
                            await Auth.signOut()
                        }
                        appRoot.signOut()
                    }
                }
            ]
        })

    return (
        <Dropdown as={NavItem} className="video-toolbar-hamburger-menu" id="video-toolbar-hamburger-menu" align="end">
            <Dropdown.Toggle as={NavLink} disabled={false}>
                <MembersIcon className="top-navigation-icon fa-user fas fa-2x" tooltip={username} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="styled-dropdown-select-menu">
                <Dropdown.Item disabled className={className}>
                    {username}
                </Dropdown.Item>
                <Dropdown.Item className={className} onClick={() => history.push('/projects')}>
                    <GenericIcon
                        iconName="fa-server"
                        className={downloadIconClassName}
                        tooltip={t('myProjectsHeader')}
                    />
                    {t('myProjectsHeader')}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleSignOut} className={className}>
                    <GenericIcon iconName="fa-sign-out-alt" className={downloadIconClassName} tooltip={t('Sign out')} />
                    {t('Sign out')}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
