/* eslint-disable import/no-cycle */
import { FC } from 'react'

import classNames from 'classnames'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import { Navbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LocaleSelector } from './LocaleSelector'
import { useOnlineStatus } from './OnlineStatusContext'
import { ProjectSelector } from './ProjectSelector'
import { useAppRoot } from './RootContext'
import { avttHeader } from './slttAvtt'
import { SyncStatus } from './SyncStatus'
import { UserDropdown } from './UserDropdown'
import { SlttHelp } from '../utils/Buttons'
import { HeadsetIcon, HelpLink } from '../utils/Icons'
import { OfflineMessage } from '../utils/OfflineMessage'

interface INavigationBar {
    selectPage: (page: string) => void
    selected: string
    selectProject: (projectName: string) => void
}

export const NavigationBar: FC<INavigationBar> = observer(({ selectPage, selected, selectProject }) => {
    const { i18n } = useTranslation()
    const appRoot = useAppRoot()
    const { isOnline } = useOnlineStatus()

    const { useMobileLayout, rt } = appRoot

    const hasPortions = rt ? Boolean(rt.project.portions.length) : false

    return (
        <Navbar className="navbar navbar-default app-navbar-bottom-margin" bg="light">
            <div className="navbar-left">
                <div className="navbar-header-title navbar-header navbar-brand">{avttHeader()}</div>
                <div className="navbar-header-project">
                    {rt && (
                        <>
                            <ProjectSelector currentRoot={rt} selectProject={selectProject} />
                            <SyncStatus rt={rt} />
                        </>
                    )}
                </div>
            </div>
            <div className="navbar-header-icons navbar-header">
                {rt && !useMobileLayout && (
                    <>
                        <Link to="/index.html" className="app-selector-icon video-camera-button">
                            <HeadsetIcon
                                className={classNames(
                                    'top-navigation-icon',
                                    'edit-passages-button',
                                    'main-headset-icon',
                                    {
                                        'app-selector-disabled': !hasPortions
                                    }
                                )}
                                tooltip={t('recordingPlayRecord')}
                            />
                        </Link>
                        {!rt.iAmBackTranslator && (
                            <>
                                <SlttHelp id="status" tooltip={t('Edit project status.')} place="bottom">
                                    <Link to="/status" className="app-selector-icon">
                                        <i
                                            className={classNames(
                                                'top-navigation-icon',
                                                'fa-list-ol',
                                                'fa',
                                                'fa-2x',
                                                'edit-status-button',
                                                {
                                                    'fa-border': selected === 'status',
                                                    'app-selector-disabled': !hasPortions
                                                }
                                            )}
                                            onClick={() => {
                                                selectPage('/status')
                                            }}
                                        />
                                    </Link>
                                </SlttHelp>

                                <SlttHelp id="portions" tooltip={t('Edit portions.')} place="bottom">
                                    <Link to="/portions" className="app-selector-icon edit-portions-button">
                                        <i
                                            className={classNames(
                                                'top-navigation-icon',
                                                'fa-chart-pie',
                                                'fas',
                                                'fa-2x',
                                                {
                                                    'fa-border': selected === 'portions'
                                                }
                                            )}
                                            onClick={() => {
                                                selectPage('/portions')
                                            }}
                                        />
                                    </Link>
                                </SlttHelp>

                                <SlttHelp id="biblical-terms" tooltip={t('projectBiblicalTerms')} place="bottom">
                                    <Link to="/key-terms" className="app-selector-icon">
                                        <i
                                            className={classNames('top-navigation-icon', 'fa-star', 'fas', 'fa-2x', {
                                                'fa-border': selected === 'key-terms'
                                            })}
                                            onClick={() => {
                                                selectPage('/key-terms')
                                            }}
                                        />
                                    </Link>
                                </SlttHelp>

                                {rt.project.isEngageEnabled && (
                                    <Link to="/project-review" className="app-selector-icon edit-portions-button">
                                        <i
                                            className={classNames(
                                                'top-navigation-icon',
                                                'fa-comments',
                                                'fas',
                                                'fa-2x',
                                                {
                                                    'fa-border': selected === 'project-review'
                                                }
                                            )}
                                            onClick={() => {
                                                selectPage('/project-review')
                                            }}
                                            title={t('projectReviewTitle')}
                                        />
                                    </Link>
                                )}
                                {rt.iAmAdmin && (
                                    <Link to="/database" className="app-selector-icon">
                                        <i
                                            className={classNames(
                                                'top-navigation-icon',
                                                'fa-database',
                                                'fas',
                                                'fa-2x',
                                                {
                                                    'fa-border': selected === 'database'
                                                }
                                            )}
                                            onClick={() => {
                                                selectPage('/database')
                                            }}
                                            data-toggle="tooltip"
                                            title="Database Records."
                                        />
                                    </Link>
                                )}
                                <SlttHelp id="settings" tooltip={t('Edit project settings.')} place="bottom">
                                    <Link to="/project-settings" className="app-selector-icon">
                                        <i
                                            className={classNames(
                                                'top-navigation-icon',
                                                'fa-cog',
                                                'fas',
                                                'fa-2x',
                                                'edit-project-settings-button',
                                                {
                                                    'fa-border': selected === 'project-settings'
                                                }
                                            )}
                                            onClick={() => {
                                                selectPage('/project-settings')
                                            }}
                                        />
                                    </Link>
                                </SlttHelp>
                            </>
                        )}
                    </>
                )}
                <HelpLink className="top-navigation-icon app-selector-icon fa-2x" tooltip={t('Get help')} />
                <UserDropdown />
                <div className="app-selector-icon">
                    <LocaleSelector currentValue={i18n.language} chooseOption={(option) => appRoot.setLocale(option)} />
                </div>
                {!isOnline && (
                    <div className="app-selector-icon">
                        <OfflineMessage />
                    </div>
                )}
            </div>
        </Navbar>
    )
})
