import { FC, useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Passage } from '../../../models3/Passage'
import { PassageVideo } from '../../../models3/PassageVideo'
import { Root } from '../../../models3/Root'
import { getReferenceParseError, ReferenceParseError } from '../../../resources/ReferenceParsers'
import { MimeType } from '../../../types'
import FilePicker from '../../filePickers/FilePicker'
import { ErrorList } from '../../utils/ErrorList'
import { ModalFooter } from '../Modals'

interface ImportVerseTimeCodesModalProps {
    passage: Passage
    passageVideo: PassageVideo
    rt: Root
    setIsModalOpen: (isOpen: boolean) => void
}

enum FileParseError {
    INVALID_NUMBER_OF_COLUMNS = 'INVALID_NUMBER_OF_COLUMNS',
    INVALID_START_OR_END_TIME_CODE = 'INVALID_START_OR_END_TIME_CODE',
    NO_REFERENCES_FOUND = 'NO_REFERENCES_FOUND',
    GENERIC = 'FILE_PARSE_ERROR_GENERIC'
}

const isStringConvertibleToNumber = (str: string) => !isNaN(Number(str))

const ImportVerseTimeCodesModal: FC<ImportVerseTimeCodesModalProps> = ({
    passage,
    passageVideo,
    rt,
    setIsModalOpen
}) => {
    const { t } = useTranslation()
    const [error, setError] = useState<string>()
    const [file, setFile] = useState<File>()

    const getErrorMessage = (err: Error) => {
        switch (err.message) {
            case FileParseError.INVALID_NUMBER_OF_COLUMNS:
            case FileParseError.INVALID_START_OR_END_TIME_CODE:
            case FileParseError.NO_REFERENCES_FOUND:
            case FileParseError.GENERIC:
                return t('invalidImportVerseTimeCodeFileErrorMessage')
            case ReferenceParseError.INVALID_BOOK_NAME:
                return t('unknownBibleBookName')
            case ReferenceParseError.GENERIC:
                return t('invalidBibleReference')
            default:
                return t('invalidImportVerseTimeCodeFileErrorMessage')
        }
    }

    const getValidationError = (fileLines: string[]) => {
        for (const line of fileLines) {
            const columns = line.split('\t')
            if (columns.length !== 3) {
                return FileParseError.INVALID_NUMBER_OF_COLUMNS
            }

            const [start, end, reference] = columns
            if (!isStringConvertibleToNumber(start) || !isStringConvertibleToNumber(end)) {
                return FileParseError.INVALID_START_OR_END_TIME_CODE
            }

            try {
                // This function throws an error if the reference is invalid
                const parsedReferences = rt.parseReferences(reference, false)
                if (!parsedReferences.length) {
                    return FileParseError.NO_REFERENCES_FOUND
                }
            } catch (err) {
                const errError = err as Error
                if (errError.message.includes('PARSING FAILED')) {
                    const referenceParseError = getReferenceParseError(errError)
                    if (referenceParseError !== undefined) {
                        return referenceParseError
                    }
                }
            }
        }
    }

    const onConfirm = async () => {
        if (!file) {
            return
        }

        try {
            const fileContent = await file.text()
            const fileLines = fileContent.split('\n')

            const validationError = getValidationError(fileLines)
            if (validationError !== undefined) {
                throw new Error(validationError)
            }

            await passageVideo.removeAllReferences(passage)
            for (const line of fileLines) {
                const values = line.split('\t')
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [start, _, reference] = values
                const startNumber = Number(start)
                const references = rt.parseReferences(reference, false)

                await passageVideo.addReference(references, startNumber)
            }
            setIsModalOpen(false)
        } catch (err) {
            setError(getErrorMessage(err as Error))
            console.error(err)
        }
    }

    return (
        <Modal backdrop="static" onHide={() => setIsModalOpen(false)} show>
            <Modal.Header closeButton>
                <Modal.Title>{t('importVerseTimeCodes')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <ErrorList errors={[{ text: error, key: '' }]} />}
                <div className="modal-file-picker">
                    <FilePicker
                        accept={MimeType.TXT}
                        className="modal-file-picker-icon"
                        enabled
                        setSelectedFiles={(fileList) => {
                            if (fileList.length > 0) {
                                const singleFile = fileList[0]
                                setFile(singleFile)
                            }
                        }}
                    />
                    {t('chooseFile')}
                </div>
                {file && <div>{file.name}</div>}
            </Modal.Body>
            <ModalFooter enabledOK={Boolean(file)} onCancel={() => setIsModalOpen(false)} onOK={onConfirm} />
        </Modal>
    )
}

export default ImportVerseTimeCodesModal
