import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { SortableItem } from './SortableItem'
import { ReviewProjectGeneralQuestion } from '../../models3/ReviewProjectGeneralQuestion'
import { ReviewRecordingMediaPlayer } from '../audio/ReviewRecordingMediaPlayer'
import { PencilButton } from '../utils/Buttons'
import { Switch } from '../utils/Switch'

export const GeneralQuestionSortableItem = observer(
    ({
        avttProjectName,
        question,
        editItem,
        iAmInterpreter
    }: {
        avttProjectName: string
        question: ReviewProjectGeneralQuestion
        editItem: () => void
        iAmInterpreter: boolean
    }) => {
        const { t } = useTranslation()
        const { text, fileName, isActive } = question

        return (
            <SortableItem
                id={question._id}
                className="general-question-sortable-container-item"
                enabled={iAmInterpreter}
            >
                <span className={`general-question-title ${isActive ? '' : 'hidden-item'}`}>{text}</span>
                <span className="general-question-audio">
                    {avttProjectName && fileName && (
                        <ReviewRecordingMediaPlayer avttProjectName={avttProjectName} url={fileName} />
                    )}
                </span>
                <span className="general-question-buttons">
                    <PencilButton
                        enabled={iAmInterpreter}
                        onClick={() => {
                            editItem()
                        }}
                        className="project-review-button default-blue-icon general-question-edit-button"
                        tooltip={t('Edit')}
                    />
                    <Switch
                        enabled={iAmInterpreter}
                        value={isActive}
                        setValue={async (value) => {
                            await question.setIsActive(value)
                        }}
                        tooltip={t('showOrHide')}
                    />
                </span>
            </SortableItem>
        )
    }
)
