import React from 'react'

import { observable } from 'mobx'
import { observer /* inject */ } from 'mobx-react'
import _ from 'underscore'

import { OldSegmentViewer } from './OldSegmentViewer'
import { SegmentDocumentEditor } from './SegmentDocumentEditor'
import { SegmentDropTarget } from './SegmentDropTarget'
import { SegmentOralTranscriber } from './SegmentOralTranscriber'
import { panelOrder, panelVisibility } from './SegmentPanelOrder'
import SegmentSelector from './SegmentSelector'
import { SegmentTimestamp } from './SegmentTimestamp'
import SegmentToolbar from './SegmentToolbar'
import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { RecordingType, SegmentPanelType } from '../../types'
import { hasReachedEnd } from '../utils/Helpers'
import { AVTTRecordingState } from '../video/VideoRecorder'

import './Segments.css'
import '../translation/Translation.css'

// Display the currently selected passageSegment and allow editing it.

interface ISegmentsEditor {
    rt: Root
    recordingState: AVTTRecordingState
}

@observer
export class SegmentsEditor extends React.Component<ISegmentsEditor> {
    @observable currentVersion: PassageVideo | null = null

    @observable currentVersionSegment: PassageSegment | null = null

    @observable oldSegmentViewerOpen = false

    @observable isEditing: SegmentPanelType | undefined = undefined

    @observable isRecording = false

    constructor(props: ISegmentsEditor) {
        super(props)
        this.stopRecording = this.stopRecording.bind(this)
        this.chooseVersionToPlay = this.chooseVersionToPlay.bind(this)
        this.openOldSegmentViewer = this.openOldSegmentViewer.bind(this)
        this.closeOldSegmentViewer = this.closeOldSegmentViewer.bind(this)
        this.record = this.record.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.isMostRecentVersion = this.isMostRecentVersion.bind(this)
        this.setIsEditing = this.setIsEditing.bind(this)
        this.setIsRecording = this.setIsRecording.bind(this)

        const { rt } = this.props
        const { passageSegment, passage, passageVideo } = rt

        if (passageSegment && passage && passageVideo) {
            if (passageSegment.isPatched) {
                const last = passageSegment.videoPatchHistory.length - 1
                const mostRecent = passageSegment.videoPatchHistory[last]
                const currentVersion = this.videoInHistory(mostRecent, passage)
                if (currentVersion) {
                    this.currentVersion = currentVersion
                    this.currentVersionSegment = currentVersion.getAllBaseSegments()[0]
                }
            } else {
                this.currentVersion = passageVideo
                this.currentVersionSegment = passageSegment
            }
        }
    }

    componentWillUnmount() {
        const { rt } = this.props
        rt.resetSegmentLabelsDraftChanges()
    }

    videoInHistory = (id: string, passage: Passage) => passage.videos.find((v) => v._id === id)

    setIsEditing = (isEditingValue?: SegmentPanelType) => {
        this.isEditing = isEditingValue
    }

    setIsRecording = (isRecordingValue: boolean) => {
        this.isRecording = isRecordingValue
    }

    stopRecording() {
        const { rt } = this.props

        rt.stop()
    }

    /**
     * Record a patch for this segment (or selection)
     */
    record(recordingType: RecordingType) {
        const { rt } = this.props
        rt.record(recordingType)
    }

    closeOldSegmentViewer() {
        this.oldSegmentViewerOpen = false
    }

    openOldSegmentViewer() {
        this.oldSegmentViewerOpen = true
    }

    isMostRecentVersion(currentVersion: PassageVideo) {
        const { rt } = this.props
        const { passageVideo, passage, passageSegment } = rt
        if (!passageVideo || !passage || !passageSegment) {
            return true
        }

        const { videoPatchHistory } = passageSegment
        if (videoPatchHistory.length > 0) {
            const last = videoPatchHistory.length - 1
            const mostRecent = videoPatchHistory[last]
            if (currentVersion._id !== mostRecent) {
                return false
            }
        }
        return true
    }

    pause() {
        const { rt } = this.props
        rt.pause()
    }

    play() {
        const { rt } = this.props
        const { passageVideo, passage, passageSegment, currentTime } = rt
        if (!passageVideo || !passage || !passageSegment) return

        const segment = passageSegment.actualSegment(passage)
        if (!segment) {
            return
        }

        const { time, endTime } = segment

        // The tolerance needs to be larger than the default to prevent us accidentally playing
        // part of the next segment. This should be fine, because generally the user doesn't want
        // to play such a short snippet of audio.
        const startTime = hasReachedEnd(currentTime, endTime, 0.1) ? time : undefined
        rt.play(startTime, endTime)
    }

    chooseVersionToPlay(id: string) {
        const { rt } = this.props
        const { passage, passageVideo, passageSegment } = rt
        if (!passage || !passageVideo || !passageSegment) {
            return
        }

        const video = this.videoInHistory(id, passage)
        if (!video) {
            return
        }

        const isMainVideo = video._id === passageVideo._id
        const segment = isMainVideo ? passageSegment : video.getAllBaseSegments()[0]

        if (this.isMostRecentVersion(video)) {
            this.closeOldSegmentViewer()
        } else {
            this.openOldSegmentViewer()
        }

        this.currentVersion = video
        this.currentVersionSegment = segment
    }

    render() {
        const { rt, recordingState } = this.props
        const { passage, passageVideo, passageSegment, portion, project, iAmTranslatorForPassage, isIdle } = rt
        const { reviewProject } = project

        if (!portion || !passage || !passageVideo || !passageSegment) return null

        // access passage._rev to force render whenever any passage info received
        // eslint-disable-next-line
        passage._rev

        // Access to force re-renders on change
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        passageSegment._id

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        passageSegment.videoPatchHistory.length

        const segments = passageVideo.visibleSegments(passage)
        const currentSegment = passageSegment.actualSegment(passage)
        if (!currentSegment) return null

        const idx = _.findIndex(segments, (ps) => ps._id === currentSegment._id)
        if (idx === -1) return null

        const {
            record,
            stopRecording,
            chooseVersionToPlay,
            closeOldSegmentViewer,
            currentVersion,
            currentVersionSegment,
            play,
            pause,
            oldSegmentViewerOpen,
            isEditing,
            isRecording
        } = this

        const defined = (e: PassageVideo | undefined) => e !== undefined
        const oldVersionOptions = passageSegment.videoPatchHistory
            .map((item) => this.videoInHistory(item, passage))
            .filter(defined)
            .reverse() as PassageVideo[]
        if (!passageSegment.isHidden) {
            oldVersionOptions.push(passageVideo)
        }
        const isInReview = passageVideo.isInReview(reviewProject)

        const recordingDate = passage.findVideo(currentSegment._id)?.creationDate ?? ''

        const selectionPresent = rt.timeline.selectionPresent()
        const patchableSelectionPresent = rt.patchableSelectionPresent()

        const baseSegments = passageVideo?.getVisibleBaseSegments() ?? []

        const recordEnabled =
            passageVideo &&
            passageSegment &&
            isIdle &&
            iAmTranslatorForPassage(passage) &&
            baseSegments.length > 1 &&
            !isInReview &&
            (!selectionPresent || patchableSelectionPresent)

        const segmentsEditorClass =
            (idx + 1) % 2 === 0
                ? 'passage-passage-segments-editor segment-even'
                : 'passage-passage-segments-editor segment-odd'

        return (
            <SegmentDropTarget passage={passage} enabled={recordEnabled}>
                <div className={segmentsEditorClass}>
                    <div className="segment-selector">
                        <SegmentSelector segment={currentSegment} rt={rt} isInReview={Boolean(isInReview)} />
                        {passageSegment.isPatched && (
                            <SegmentTimestamp {...{ rt, passage, passageSegment, currentSegment }} />
                        )}
                    </div>
                    <div className="segment-editor-bottom">
                        {panelVisibility(project, 'recordingPanel') && (
                            <>
                                <SegmentToolbar
                                    rt={rt}
                                    record={record}
                                    segment={currentSegment}
                                    isAppendedSegment={passageSegment.isHidden}
                                    baseSegment={passageSegment}
                                    stopRecording={stopRecording}
                                    oldVersionOptions={oldVersionOptions}
                                    chooseVersionToPlay={chooseVersionToPlay}
                                    play={play}
                                    pause={pause}
                                    className={panelOrder(project, 'recordingPanel')}
                                    recordingState={recordingState}
                                    isInReview={Boolean(isInReview)}
                                    recordEnabled={recordEnabled}
                                />
                                {oldSegmentViewerOpen && currentVersion && currentVersionSegment && (
                                    <OldSegmentViewer
                                        key={currentVersion._id} // Component will be recreated when this changes
                                        rt={rt}
                                        originalSegment={passageSegment}
                                        originalVideo={passageVideo}
                                        video={currentVersion}
                                        segment={currentVersionSegment}
                                        onDone={closeOldSegmentViewer}
                                        passage={passage}
                                    />
                                )}
                            </>
                        )}
                        {panelVisibility(project, 'backTranslationPanel') && (
                            <>
                                <SegmentOralTranscriber
                                    segment={currentSegment}
                                    className={panelOrder(project, 'backTranslationPanel')}
                                    isEditing={isEditing}
                                    readOnly={!rt.iAmInterpreter && !rt.iAmBackTranslator}
                                    setIsEditing={this.setIsEditing}
                                    setIsRecording={this.setIsRecording}
                                    isRecording={isRecording}
                                />
                                <SegmentDocumentEditor
                                    segment={currentSegment}
                                    documentType="backTranslationText"
                                    className={panelOrder(project, 'backTranslationPanel')}
                                    showMessage
                                    recordingDate={recordingDate}
                                    readOnly={!rt.iAmInterpreter && !rt.iAmBackTranslator}
                                    isEditing={isEditing}
                                    setIsEditing={this.setIsEditing}
                                    dateFormatter={rt.dateFormatter}
                                    isRecording={isRecording}
                                />
                            </>
                        )}
                        {panelVisibility(project, 'transcriptionPanel') && (
                            <SegmentDocumentEditor
                                segment={currentSegment}
                                documentType="transcription"
                                className={panelOrder(project, 'transcriptionPanel')}
                                showMessage
                                recordingDate={recordingDate}
                                readOnly={isInReview || (!rt.iAmInterpreter && !rt.iAmBackTranslator)}
                                isEditing={isEditing}
                                setIsEditing={this.setIsEditing}
                                dateFormatter={rt.dateFormatter}
                                isRecording={isRecording}
                            />
                        )}
                    </div>
                </div>
            </SegmentDropTarget>
        )
    }
}
