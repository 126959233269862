import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { SortableItem } from './SortableItem'
import { ReviewProjectProfileAttribute } from '../../models3/ReviewProjectProfileAttribute'
import { PencilButton } from '../utils/Buttons'
import { Switch } from '../utils/Switch'

export const ProfileAttributeSortableItem = observer(
    ({
        attribute,
        editItem,
        iAmInterpreter
    }: {
        attribute: ReviewProjectProfileAttribute
        editItem: () => void
        iAmInterpreter: boolean
    }) => {
        const { t } = useTranslation()
        const { label, options, isActive } = attribute

        return (
            <SortableItem
                id={attribute._id}
                className="profile-attribute-sortable-container-item"
                enabled={iAmInterpreter}
            >
                <span className={`profile-attribute-label ${isActive ? '' : 'hidden-item'}`}>{label}</span>
                <span className={`profile-attribute-options ${isActive ? '' : 'hidden-item'}`}>
                    <ul>
                        {options.map((option) => (
                            <li key={option.id}>{option.text}</li>
                        ))}
                    </ul>
                </span>
                <span className="profile-attribute-buttons">
                    <PencilButton
                        enabled={iAmInterpreter}
                        onClick={() => {
                            editItem()
                        }}
                        className="project-review-button default-blue-icon profile-attribute-edit-button"
                        tooltip={t('Edit')}
                    />
                    <Switch
                        enabled={iAmInterpreter}
                        value={isActive}
                        setValue={async (value) => {
                            await attribute.setIsActive(value)
                        }}
                        tooltip={t('showOrHide')}
                    />
                </span>
            </SortableItem>
        )
    }
)
