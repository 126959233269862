import { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

import { EditorHeader, FormControls, SegmentEditorPanel } from './SegmentEditorPanel'
import { IDateFormatter } from '../../models3/DateUtilities'
import { PassageSegment } from '../../models3/PassageSegment'
import { SegmentDocumentType, SegmentPanelType } from '../../types'
import { displayError } from '../utils/Errors'
import { getTimeStamp } from '../utils/Helpers'
import { TextHistoryDropdown } from '../utils/TextHistoryDropdown'
import { WarningList } from '../utils/WarningList'

import './Segments.css'

interface SegmentDocumentEditorProps {
    segment: PassageSegment
    documentType: SegmentDocumentType
    className: string
    isEditing?: SegmentPanelType
    setIsEditing: (isEditing?: SegmentPanelType) => void
    readOnly?: boolean
    showMessage?: boolean
    recordingDate?: string
    dateFormatter: IDateFormatter
    isRecording?: boolean
}

export const SegmentDocumentEditor = ({
    segment,
    documentType,
    className,
    isEditing,
    setIsEditing,
    recordingDate,
    readOnly,
    showMessage,
    dateFormatter,
    isRecording
}: SegmentDocumentEditorProps) => {
    const { t } = useTranslation()
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const document = segment.getCurrentDocument(documentType)
    const { text: initialText, modDate: initialModDate } = document ?? { text: '', modDate: '' }

    const textHistory = document?.textHistory.slice().reverse() || []

    const mostRecentVersion = textHistory.length > 0 ? textHistory[0] : undefined

    const [text, setText] = useState(initialText)
    const [initText, setInitText] = useState(initialText)
    const [date, setDate] = useState(initialModDate)
    const [isOlderVersion, setIsOlderVersion] = useState<boolean>(false)
    const [versionTimeStamp, setVersionTimeStamp] = useState<string>('')
    const saveRef = useRef<() => Promise<void>>(() => Promise.resolve())

    useEffect(() => {
        if (textAreaRef.current && isEditing) {
            textAreaRef.current.focus()
        }
    }, [isEditing])

    useEffect(() => {
        setIsEditing(undefined)
    }, [segment, setIsEditing])

    useEffect(() => {
        setVersionTimeStamp(
            mostRecentVersion ? getTimeStamp(mostRecentVersion.modBy, mostRecentVersion.modDate, dateFormatter) : ''
        )
        setText(initialText)
        setInitText(initialText)
        setDate(initialModDate)
    }, [initialModDate, initialText, dateFormatter, mostRecentVersion])

    const isEditingDocType = isEditing === documentType

    const saveDocumentText = async () => {
        if (initText !== text && isEditingDocType) {
            if (document) {
                await document.setText(text)
            } else {
                const newDocument = segment.createDocument(documentType)
                newDocument.text = text
                await segment.addDocument(documentType, newDocument)
            }
            setInitText(text)
            setText(text)
        }
    }

    const handleDocumentSave = async () => {
        try {
            await saveDocumentText()
            setIsEditing(undefined)
        } catch (error) {
            displayError(error)
        }
    }

    useEffect(() => {
        const handleKeyDown = async (e: KeyboardEvent) => {
            if (e.code === 'KeyS' && e.ctrlKey && e.altKey) {
                e.preventDefault()
                await saveDocumentText()
            }
        }
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    })

    const editorClassName = 'segment-text-editor__editor'

    const enableEditButton = !readOnly && !isEditing && !isRecording

    useEffect(() => {
        saveRef.current = handleDocumentSave
    })

    useEffect(() => {
        return () => {
            saveRef.current?.()
        }
    }, [])

    const handleViewOlderVersion = (
        index: number,
        selectedText: string,
        selectedName: string,
        selectedDate: string
    ) => {
        setVersionTimeStamp(getTimeStamp(selectedName, selectedDate, dateFormatter))
        setIsOlderVersion(index !== 0)
        setText(selectedText)
    }

    return (
        <SegmentEditorPanel isEditing={isEditingDocType} className={className}>
            <EditorHeader
                headerText={documentType === 'transcription' ? t('transcriptionPanel') : t('backTranslationPanel')}
                onClick={() => {
                    setIsEditing(documentType)
                }}
                isEditEnabled={enableEditButton && !isOlderVersion}
                rightContent={
                    !isEditing ? (
                        <TextHistoryDropdown
                            versionTimeStamp={versionTimeStamp}
                            textHistory={textHistory}
                            dateFormatter={dateFormatter}
                            disabled={false}
                            handleViewOlderVersion={handleViewOlderVersion}
                        />
                    ) : null
                }
            />

            {showMessage && recordingDate && date && date < recordingDate && (
                <WarningList warnings={[{ text: t('olderThanSegment'), key: 'older-than-segment' }]} />
            )}

            <TextareaAutosize
                className={editorClassName}
                value={text}
                onChange={(e) => setText(e.target.value)}
                disabled={!isEditingDocType}
                spellCheck={documentType !== 'transcription'}
                dir="auto"
                ref={textAreaRef}
            />

            {isEditingDocType && (
                <FormControls
                    enableSaveButton={initText !== text}
                    onSave={handleDocumentSave}
                    onCancel={() => {
                        setIsEditing(undefined)
                        setText(initText)
                    }}
                />
            )}
        </SegmentEditorPanel>
    )
}
