import { useEffect, useMemo, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

import { OralTranscriber } from './OralTranscriber'
import { useOnlineStatus } from '../../app/OnlineStatusContext'
import { useAppRoot } from '../../app/RootContext'
import { Option } from '../../projectSettings/projectResources/MultiSelect'
import { AudioRecorder, AVTTRecordingState } from '../../video/VideoRecorder'
import { LiveWaveformVisualizerWrapper } from '../../video/WaveformVisualizer'
import { PauseButton, RecordButton, StopButton } from '../Buttons'
import { displayError } from '../Errors'

type OralTranscriberRecorderProps = {
    closeRecorder: () => void
    onDoneRecording: (blob: Blob, text?: string) => void
    language: Option<string>
}

export const OralTranscriberRecorder = ({ closeRecorder, onDoneRecording, language }: OralTranscriberRecorderProps) => {
    const { isOnline } = useOnlineStatus()
    const { t } = useTranslation()
    const { rt } = useAppRoot()
    const { autoGainControl, recordingCountdown } = rt?.project ?? {}

    const [recordingState, setRecordingState] = useState<AVTTRecordingState>('NOT_INITIALIZED')
    const [isTranscriptionStarted, setIsTranscriptionStarted] = useState(false)
    const [transcriptionParts, setTranscriptionParts] = useState<string[]>([''])
    const [isTranscriptionInProcess, setIsTranscriptionInProcess] = useState(false)
    const [finalBlob, setFinalBlob] = useState<Blob>()
    const [isStopRequested, setIsStopRequested] = useState(false)
    const [text, setText] = useState('')
    const isDoneRef = useRef(false)

    useEffect(() => {
        setText(transcriptionParts.join(' '))
    }, [transcriptionParts])

    // Only create once
    const audioRecorder = useMemo(() => {
        return new AudioRecorder({
            recordingCountdown,
            onRecordingStateChange: setRecordingState,
            autoGainControl
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Only create once
    const transcriber = useMemo(() => {
        if (!isOnline) {
            return undefined
        }

        if (language.value && OralTranscriber.supportedLanguages.some(({ value }) => value === language.value)) {
            return new OralTranscriber(language.value)
        }

        return undefined
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!transcriber) {
            setIsTranscriptionInProcess(false)
            return
        }

        const onStart = () => {
            setIsTranscriptionInProcess(true)
        }

        const onUpdate = (transcription: string) => {
            setTranscriptionParts((prev) => [...prev.slice(0, prev.length - 1), transcription])
        }

        const onError = () => {
            onUpdate('')
            displayError(t('An error occurred while transcribing'))
        }

        const onEnd = () => {
            setTranscriptionParts((prev) => [...prev, ''])
            setIsTranscriptionInProcess(false)
        }

        transcriber.addListener('onstart', onStart)
        transcriber.addListener('onupdate', onUpdate)
        transcriber.addListener('onerror', onError)
        transcriber.addListener('onend', onEnd)

        return () => {
            transcriber.removeListener('onstart', onStart)
            transcriber.removeListener('onupdate', onUpdate)
            transcriber.removeListener('onerror', onError)
            transcriber.removeListener('onend', onEnd)
        }
    }, [transcriber, t])

    useEffect(() => {
        if (finalBlob && !isTranscriptionInProcess && !isDoneRef.current) {
            isDoneRef.current = true
            const textToSave = transcriber ? text : undefined
            onDoneRecording(finalBlob, textToSave)
        }

        function _onDoneRecording(blob: Blob) {
            setFinalBlob(blob)
            if (!isTranscriptionInProcess) {
                if (isDoneRef.current) {
                    return
                }
                isDoneRef.current = true
                const textToSave = transcriber ? text : undefined
                onDoneRecording(blob, textToSave)
            }
        }

        function _onError() {
            closeRecorder()
        }

        audioRecorder.addListener('onRecordingDone', _onDoneRecording)
        audioRecorder.addListener('onError', _onError)

        return () => {
            audioRecorder.removeListener('onRecordingDone', _onDoneRecording)
            audioRecorder.removeListener('onError', _onError)
        }
    }, [audioRecorder, closeRecorder, finalBlob, isTranscriptionInProcess, onDoneRecording, text, transcriber])

    useEffect(() => {
        return () => {
            transcriber?.stop()
            audioRecorder.cancel()
        }
    }, [audioRecorder, transcriber])

    useEffect(() => {
        if (recordingState === 'RECORDING' && !isTranscriptionStarted) {
            transcriber?.start()
            setIsTranscriptionStarted(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordingState, isTranscriptionStarted])

    useEffect(() => {
        setTimeout(audioRecorder._record.bind(audioRecorder), 1000)

        return () => {
            if (audioRecorder.mediaRecorder) {
                const { state } = audioRecorder.mediaRecorder
                if (state === 'recording' || state === 'paused') {
                    audioRecorder.cancel()
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="audio-button-row">
                {recordingState === 'PAUSED' ? (
                    <RecordButton
                        enabled={!isTranscriptionInProcess}
                        onClick={() => {
                            audioRecorder.resume()
                            transcriber?.start()
                        }}
                        className="small-button audio-recorder-record-button segment-document-record-button"
                        tooltip={t('Continue recording')}
                    />
                ) : (
                    <PauseButton
                        enabled={recordingState === 'RECORDING'}
                        onClick={() => {
                            audioRecorder.pause()
                            transcriber?.stop()
                        }}
                        className="right-pane-button segment-document-pause-button"
                        tooltip={t('Pause recording')}
                    />
                )}
                <StopButton
                    enabled={
                        recordingState !== 'NOT_INITIALIZED' && recordingState !== 'INITIALIZED' && !isStopRequested
                    }
                    onClick={() => {
                        audioRecorder.stopRecording() // will trigger onRecordingDone listener
                        transcriber?.stop()
                        setIsStopRequested(true)
                    }}
                    className="right-pane-button segment-document-stop-button"
                    tooltip={t('Stop recording and preview it')}
                />
            </div>
            <div className="audio-recorder-visualization-wrapper">
                <LiveWaveformVisualizerWrapper
                    mediaStream={audioRecorder.mediaStream}
                    recordingState={recordingState}
                    isAudioOnly
                    className="audio-recorder-visualization"
                />
            </div>
            {transcriber && (
                <TextareaAutosize className="segment-text-editor__editor" value={text} disabled spellCheck dir="auto" />
            )}
        </div>
    )
}
