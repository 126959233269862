/* eslint-disable react/jsx-props-no-spreading */
import { PropsWithChildren } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { DragAndDropIcon } from '../utils/Icons'

interface SortableItemProps {
    id: string
    className: string
    enabled: boolean
}

export const SortableItem = ({ id, className, children, enabled }: PropsWithChildren<SortableItemProps>) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

    return (
        <div
            className={className}
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition
            }}
        >
            {enabled && (
                <div {...attributes} {...listeners}>
                    <DragAndDropIcon className="fa-lg" />
                </div>
            )}
            {children}
        </div>
    )
}
