import { useTranslation } from 'react-i18next'

import { Project } from '../../models3/Project'
import { VisibleTimestamp } from '../../models3/VisibleTimestamp'
import { useAppRoot } from '../app/RootContext'
import { getEmailPrefix, getModByFromDoc, MailToLink } from '../utils/Helpers'

const ProjectActivityInfo = ({ project }: { project: Project }) => {
    const appRoot = useAppRoot()
    const dateFormatter = appRoot.rt?.dateFormatter

    const { t } = useTranslation()
    const { projectActivityInfo } = project

    if (!projectActivityInfo || !dateFormatter) {
        return null
    }

    const { firstDocDate, firstDoc, lastDocDate, lastDoc } = projectActivityInfo
    const firstDocModBy = getModByFromDoc(firstDoc)
    const lastDocModBy = getModByFromDoc(lastDoc)

    const className = 'projects-table-timestamp'

    return (
        <div>
            {firstDocDate && (
                <div>
                    {`${t('first')}: `}
                    <MailToLink email={firstDocModBy}>{getEmailPrefix(firstDocModBy)}</MailToLink>{' '}
                    <VisibleTimestamp
                        name=""
                        dateBy={firstDocDate}
                        className={className}
                        dateFormatter={dateFormatter}
                    />
                </div>
            )}
            {lastDocDate && (
                <div>
                    {`${t('last')}: `}
                    <MailToLink email={lastDocModBy}>{getEmailPrefix(lastDocModBy)}</MailToLink>{' '}
                    <VisibleTimestamp
                        name=""
                        dateBy={lastDocDate}
                        className={className}
                        dateFormatter={dateFormatter}
                    />
                </div>
            )}
        </div>
    )
}

export default ProjectActivityInfo
