import { useEffect, useState } from 'react'

import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import { SegmentEditorPanel } from './SegmentEditorPanel'
import { useOralTranscriptionLanguage } from '../../hooks/useOralTranscriptionLanguage'
import { PassageSegment } from '../../models3/PassageSegment'
import { SegmentPanelType } from '../../types'
import { useOnlineStatus } from '../app/OnlineStatusContext'
import { useAppRoot } from '../app/RootContext'
import { AudioPlayerWithPlaceholder } from '../audio/AudioPlayerWithPlaceholder'
import { Option } from '../projectSettings/projectResources/MultiSelect'
import Select from '../select/Select'
import { DeleteButton, RecordButton } from '../utils/Buttons'
import { displayError, systemError } from '../utils/Errors'
import { OralTranscriberRecorder } from '../utils/oralTranscriber/OralTranscriberRecorder'
import { saveRecording } from '../video/VideoUploader'

import './Segments.css'

const RecordingControls = ({
    enableDeleteButton,
    setIsRecording,
    onDelete,
    saveTranscriberLanguage,
    transcriberLanguage,
    supportedLanguages,
    isEditing,
    setIsEditing,
    readOnly
}: {
    enableDeleteButton: boolean
    setIsRecording: (value: boolean) => void
    onDelete: () => void
    saveTranscriberLanguage: (selected: Option<string>[]) => void
    transcriberLanguage: { value: string; label: string }
    supportedLanguages: Option<string>[]
    isEditing?: SegmentPanelType
    setIsEditing: (isEditing?: SegmentPanelType) => void
    readOnly?: boolean
}) => {
    const { t } = useTranslation()
    const { isOnline } = useOnlineStatus()

    return (
        <div className="audio-button-row">
            <RecordButton
                enabled={!isEditing && !readOnly}
                className="small-button audio-recorder-record-button"
                onClick={() => {
                    setIsRecording(true)
                    setIsEditing('backTranslationAudio')
                }}
                tooltip={t('Start recording')}
            />
            {enableDeleteButton && (
                <DeleteButton
                    enabled={!isEditing && !readOnly}
                    className="small-button"
                    onClick={() => {
                        onDelete()
                    }}
                    tooltip={t('Delete')}
                />
            )}
            <Select
                className="multi-select"
                isDisabled={!isOnline}
                onChange={(selected) => saveTranscriberLanguage(selected ? [selected] : [])}
                options={supportedLanguages}
                value={transcriberLanguage}
            />
        </div>
    )
}

interface SegmentOralTranscriberProps {
    segment: PassageSegment
    className: string
    isEditing?: SegmentPanelType
    setIsEditing: (isEditing?: SegmentPanelType) => void
    readOnly?: boolean
    setIsRecording: (isRecording: boolean) => void
    isRecording: boolean
}

export const SegmentOralTranscriber = ({
    segment,
    isEditing,
    setIsEditing,
    readOnly,
    setIsRecording,
    isRecording
}: SegmentOralTranscriberProps) => {
    const { t } = useTranslation()
    const { rt } = useAppRoot()
    const { transcriberLanguage, saveTranscriberLanguage, supportedLanguages } = useOralTranscriptionLanguage()

    const audioClip = segment.getCurrentAudioClip()
    const initialAudioUrl = audioClip ? audioClip.url : ''

    const [audioUrl, setAudioUrl] = useState(initialAudioUrl)

    useEffect(() => {
        setAudioUrl(initialAudioUrl)
    }, [initialAudioUrl])

    const saveAudioRecording = async (blob: Blob) => {
        if (!rt) {
            systemError('No root')
            return
        }
        const audioClipToSave = segment.createAudioClip(rt.name)
        const result = await saveRecording(blob, audioClipToSave.url)
        if (result.type === 'error') {
            displayError(result.error)
            return
        }

        const { url, duration } = result
        audioClipToSave.url = url
        audioClipToSave.duration = duration
        await segment.addAudioClip(audioClipToSave)
        setAudioUrl(audioClipToSave.url)
    }

    const saveText = async (text: string) => {
        const document = segment.getCurrentDocument('backTranslationText')
        if (document) {
            await document.setText(text)
        } else {
            const newDocument = segment.createDocument('backTranslationText')
            newDocument.text = text
            await segment.addDocument('backTranslationText', newDocument)
        }
    }

    const closeEditor = () => {
        setIsEditing(undefined)
    }

    const handleSave = async (blob: Blob, text?: string) => {
        try {
            if (text !== undefined) {
                await saveText(text)
            }
            await saveAudioRecording(blob)
            closeEditor()
        } catch (error) {
            displayError(error)
        }
    }

    const onDelete = () => {
        if (!audioClip) {
            return
        }
        confirmAlert({
            title: t('backTranslationAudioDeleteQuestion'),
            message: t(
                'Are you sure you want to delete the back translation for this segment? It is not easy to get it back once you have deleted it.'
            ),
            buttons: [
                {
                    label: t('Cancel'),
                    onClick: () => {}
                },
                {
                    label: t('OK'),
                    onClick: async () => {
                        await segment.removeAudioClip(audioClip._id)
                        setAudioUrl('')
                        setIsRecording(false)
                        closeEditor()
                    }
                }
            ]
        })
    }

    return (
        <SegmentEditorPanel isEditing={isEditing === 'backTranslationAudio'} className="segment-oral-transcriber">
            <div className="segment-dialog-heading-group">
                <div className="segment-dialog-heading">{t('backTranslationAudioPanel')}</div>
            </div>
            {!isRecording && (
                <RecordingControls
                    enableDeleteButton={Boolean(audioUrl)}
                    setIsRecording={setIsRecording}
                    saveTranscriberLanguage={saveTranscriberLanguage}
                    transcriberLanguage={transcriberLanguage}
                    supportedLanguages={supportedLanguages}
                    onDelete={onDelete}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    readOnly={readOnly}
                />
            )}
            {isRecording && (
                <OralTranscriberRecorder
                    closeRecorder={() => {
                        setIsRecording(false)
                    }}
                    onDoneRecording={async (blob, text) => {
                        setIsRecording(false)
                        await handleSave(blob, text)
                    }}
                    language={transcriberLanguage}
                />
            )}
            {!isRecording && audioUrl && (
                <div className="segment-document-player">
                    <AudioPlayerWithPlaceholder url={audioUrl} />
                </div>
            )}
        </SegmentEditorPanel>
    )
}
