import { FC } from 'react'

import { TFunction, useTranslation } from 'react-i18next'

import { AppRoot } from '../../models3/AppRoot'
import { IDateFormatter } from '../../models3/DateUtilities'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { useAppRoot } from '../app/RootContext'
import { DownloadButton } from '../utils/Buttons'
import { exportToFile, createLink, getTimeStamp, getModByFromDoc } from '../utils/Helpers'

const getHeaders = (t: TFunction, isRootMode?: boolean): string[] => {
    const baseHeaders = [
        t('Group'),
        t('Project'),
        t('projectLanguage'),
        t('projectLanguageCode'),
        t('firstProjectActivity'),
        t('firstProjectActivityTime'),
        t('lastProjectActivity'),
        t('lastProjectActivityTime'),
        t('firstPassageRecording'),
        t('firstPassageRecordingTime'),
        t('lastPassageRecording'),
        t('lastPassageRecordingTime')
    ]

    if (isRootMode) {
        baseHeaders.push(
            t('projectProfilesStatus'),
            t('projectProfilesPartner'),
            t('projectProfilesCountry'),
            t('projectProfilesNotes')
        )
    }

    return baseHeaders
}

const getProjectRow = (
    project: Project,
    languageTagsMap: Map<string, string>,
    group?: string,
    dateFormatter?: IDateFormatter,
    isRootMode?: boolean
): string[] => {
    const languageCode = project.language
    const languageName = languageTagsMap.get(languageCode) || ''

    const {
        name: projectName,
        getFormattedDisplayName,
        projectActivityInfo,
        projectProfiles,
        projectRecordingInfo
    } = project
    const { status = '', partner = '', country = '', notes = '' } = projectProfiles || {}
    const { firstDoc = '', firstDocDate = '', lastDoc = '', lastDocDate = '' } = projectActivityInfo || {}
    const { firstPassageRecordingDate, firstPassageRecordingId, lastPassageRecordingDate, lastPassageRecordingId } =
        projectRecordingInfo || {}

    const baseRow = [
        group ?? '',
        getFormattedDisplayName(),
        languageCode,
        languageName,
        getModByFromDoc(firstDoc),
        firstDocDate ? getTimeStamp('', firstDocDate, dateFormatter) : '',
        getModByFromDoc(lastDoc),
        lastDocDate ? getTimeStamp('', lastDocDate, dateFormatter) : '',
        firstPassageRecordingId ? createLink({ projectName, itemId: firstPassageRecordingId }) : '',
        firstPassageRecordingDate ? getTimeStamp('', firstPassageRecordingDate, dateFormatter) : '',
        lastPassageRecordingId ? createLink({ projectName, itemId: lastPassageRecordingId }) : '',
        lastPassageRecordingDate ? getTimeStamp('', lastPassageRecordingDate, dateFormatter) : ''
    ]

    if (isRootMode) {
        baseRow.push(status, partner, country, notes)
    }

    return baseRow
}

const getRows = (
    appRoot: AppRoot,
    languageTagsMap: Map<string, string>,
    groupRoot?: Root,
    isRootMode?: boolean
): string[][] => {
    const { rt, rts, getAllProjectRootsSorted, sortRootProjects } = appRoot
    const dateFormatter = rt?.dateFormatter

    const roots = groupRoot
        ? rts.filter(({ project }) => groupRoot.groupProjects?.includes(project.name)).sort(sortRootProjects)
        : getAllProjectRootsSorted?.() || []

    return roots.map(({ group, project }) => getProjectRow(project, languageTagsMap, group, dateFormatter, isRootMode))
}

interface ProjectsEditorCSVExportButtonProps {
    languageTagsMap: Map<string, string>
    groupRoot?: Root
}

export const ProjectsEditorCSVExportButton: FC<ProjectsEditorCSVExportButtonProps> = ({
    languageTagsMap,
    groupRoot
}) => {
    const { t } = useTranslation()
    const appRoot = useAppRoot()
    const isRootMode = appRoot.rt?.iAmRoot || appRoot.rt?.iAmRootObserver
    const SEPARATOR = `\t`

    const handleDownloadCSV = () => {
        const headers = getHeaders(t, isRootMode)
        const rows = getRows(appRoot, languageTagsMap, groupRoot, isRootMode)

        const tsvContent = [headers, ...rows].map((row) => row.join(SEPARATOR)).join('\n')
        const blob = new Blob([tsvContent], { type: 'text/tab-separated-values;charset=utf-8;' })

        exportToFile(blob, 'AVTT-projects', '.csv')
    }

    return (
        <DownloadButton className="csv-export-button" enabled onClick={handleDownloadCSV} tooltip={t('exportAsCSV')} />
    )
}
