import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Project } from '../../models3/Project'
import { VisibleTimestamp } from '../../models3/VisibleTimestamp'
import { useAppRoot } from '../app/RootContext'
import { systemError } from '../utils/Errors'
import { createLink } from '../utils/Helpers'

const ProjectRecordingInfo = ({ project }: { project: Project }) => {
    const appRoot = useAppRoot()
    const dateFormatter = appRoot.rt?.dateFormatter

    const { t } = useTranslation()
    const { name: projectName, projectRecordingInfo } = project

    if (!projectRecordingInfo || !dateFormatter) {
        return null
    }

    const { firstPassageRecordingDate, firstPassageRecordingId, lastPassageRecordingDate, lastPassageRecordingId } =
        projectRecordingInfo

    const className = 'projects-table-timestamp'

    const handleOnClick = () => appRoot.setCurrentProject(projectName).catch(systemError)

    return (
        <div>
            {firstPassageRecordingDate && firstPassageRecordingId && (
                <div>
                    <Link
                        onClick={handleOnClick}
                        to={createLink({ projectName, itemId: firstPassageRecordingId, generateRelative: true })}
                    >
                        {`${t('first')}:`}
                    </Link>{' '}
                    <VisibleTimestamp
                        name=""
                        dateBy={firstPassageRecordingDate}
                        className={className}
                        dateFormatter={dateFormatter}
                    />
                </div>
            )}
            {lastPassageRecordingDate && lastPassageRecordingId && (
                <div>
                    <Link
                        onClick={handleOnClick}
                        to={createLink({ projectName, itemId: lastPassageRecordingId, generateRelative: true })}
                    >
                        {`${t('last')}:`}
                    </Link>{' '}
                    <VisibleTimestamp
                        name=""
                        dateBy={lastPassageRecordingDate}
                        className={className}
                        dateFormatter={dateFormatter}
                    />
                </div>
            )}
        </div>
    )
}

export default ProjectRecordingInfo
