import { useState } from 'react'

import {
    DndContext,
    closestCorners,
    KeyboardSensor,
    PointerSensor,
    useDroppable,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { observer } from 'mobx-react'

import { EngagePassageSortableItem } from './EngagePassageSortableItem'
import { EngagePassageTitleModal } from './EngagePassageTitleModal'
import { ReviewProject } from '../../models3/ReviewProject'
import { move } from '../../models3/Utils'
import { useAppRoot } from '../app/RootContext'
import { createLink, getTimeStamp } from '../utils/Helpers'

type EngagePassageListProps = {
    projectName: string
    reviewProject: ReviewProject
    handleSetPassage: (passageId: string) => void
}

export const EngagePassageList = observer(
    ({ projectName, reviewProject, handleSetPassage }: EngagePassageListProps) => {
        const appRoot = useAppRoot()
        const dateFormatter = appRoot.rt?.dateFormatter

        const engagedPassages = reviewProject.passageRecordings.filter((recording) => recording.isActive)
        const initialItems: { [key: string]: string[] } = {
            engagePassageList: engagedPassages.map((passage) => passage._id)
        }

        const [items, setItems] = useState(initialItems)
        const [passageId, setPassageId] = useState('')

        const { setNodeRef } = useDroppable({ id: 'reviewProjectEngagedPassages' })

        const sensors = useSensors(
            useSensor(PointerSensor),
            useSensor(KeyboardSensor, {
                coordinateGetter: sortableKeyboardCoordinates
            })
        )

        const findContainer = (id: string) => {
            if (id in items) {
                return id
            }

            return Object.keys(items).find((key: string) => items[key].includes(id))
        }

        const handleDragEnd = async (event: any) => {
            const { active, over } = event
            const { id } = active
            const { id: overId } = over

            const activeContainer = findContainer(id)
            const overContainer = findContainer(overId)

            if (!activeContainer || !overContainer || activeContainer !== overContainer) {
                return
            }

            const activeIndex = items[activeContainer].indexOf(id)
            const overIndex = items[overContainer].indexOf(overId)
            if (activeIndex !== overIndex) {
                const newItems = {
                    ...items,
                    [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
                }
                setItems(newItems)
                await move(engagedPassages, activeIndex, overIndex)
            }
        }

        const handleModal = (id: string) => {
            setPassageId(id)
        }

        const passageFound = engagedPassages.find((passage) => passage._id === passageId)

        return (
            <div className="profile-attributes-table">
                <div className="profile-attribute-preferences">
                    {passageFound && (
                        <EngagePassageTitleModal closeModal={() => setPassageId('')} passage={passageFound} />
                    )}
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCorners}
                        modifiers={[restrictToParentElement]}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            id="reviewProjectEngagedPassages"
                            items={items.engagePassageList}
                            strategy={verticalListSortingStrategy}
                        >
                            <div ref={setNodeRef} className="profile-attribute-sortable-container">
                                {engagedPassages.map((engagedPassage) => {
                                    const p = appRoot.rt?.project.findPassage(engagedPassage.passageRecordingId)
                                    const versionLabel = p?.findVideo(engagedPassage.passageRecordingId)?.label(p) || ''
                                    const link = createLink({
                                        projectName,
                                        itemId: engagedPassage.passageRecordingId,
                                        generateRelative: true
                                    })
                                    const onClick = () => handleSetPassage(engagedPassage.passageRecordingId)
                                    return (
                                        <EngagePassageSortableItem
                                            key={engagedPassage._id}
                                            id={engagedPassage._id}
                                            name={engagedPassage.title}
                                            editedName={engagedPassage.editedTitle}
                                            date={getTimeStamp('', engagedPassage.creationDate, dateFormatter)}
                                            link={link}
                                            onClick={onClick}
                                            openModal={handleModal}
                                            versionLabel={versionLabel}
                                        />
                                    )
                                })}
                            </div>
                        </SortableContext>
                    </DndContext>
                </div>
            </div>
        )
    }
)
