import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Root } from '../../../models3/Root'
import { SortableItem } from '../../projectReview/SortableItem'
import { StudyGuideTabIcon } from '../../translation/StudyGuideTabIcon'
import { StudyTabKey, studyTabsVisibility } from '../../translation/StudyTabsOrder'
import { Switch } from '../../utils/Switch'

export const StudyTabLayoutSortableItem = ({ rt, id }: { rt: Root; id: StudyTabKey }) => {
    const { t } = useTranslation()
    const { project } = rt
    const [visible, setVisible] = useState(studyTabsVisibility(project, id))

    return (
        <SortableItem id={id} className="sortable-container-item horizontal-sortable-container-item" enabled>
            <span className={visible ? '' : 'hidden-item'}>
                <StudyGuideTabIcon tabName={id} />
            </span>
            <Switch
                value={visible}
                setValue={async (value) => {
                    setVisible(value)
                    await project.setStudyTabHiddenTabs(id, value)
                }}
                tooltip={t('showOrHide')}
                className="sortable-container-item-switch"
            />
        </SortableItem>
    )
}
