import { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { EngagePassageList } from './EngagePassageList'
import { GeneralConfigModal } from './GeneralConfigModal'
import { GeneralQuestions } from './GeneralQuestions'
import { ProfileAttributesView } from './ProfileAttributesView'
import { ProjectInfo } from './ProjectInfo'
import { ProjectReviewModal } from './ProjectReviewModal'
import { ReviewResponses } from './Responses'
import { useReviewProjectTabIndex } from './useReviewProjectTabIndex'
import { ReviewProject } from '../../models3/ReviewProject'
import { Root } from '../../models3/Root'
import { engageAppUrl } from '../app/slttAvtt'
import { ClipboardButton, GenericIconButton } from '../utils/Buttons'
import { buildEngageUrl } from '../utils/Engage'
import ErrorBoundary, { displayError, displayInfo } from '../utils/Errors'
import { GenericIcon } from '../utils/Icons'
import LoadingMessage from '../utils/InitializationMessage'
import { Switch } from '../utils/Switch'

import './ProjectReview.css'

interface GeneralTabProps {
    iAmAdmin: boolean
    iAmInterpreter: boolean
    project: ReviewProject
    avttProjectName: string
    setShowGeneralConfigModal: (value: boolean) => void
}

const GeneralTab = observer(
    ({ iAmAdmin, iAmInterpreter, project, avttProjectName, setShowGeneralConfigModal }: GeneralTabProps) => {
        return (
            <div>
                <ProjectInfo
                    iAmAdmin={iAmAdmin}
                    project={project}
                    setShowGeneralConfigModal={setShowGeneralConfigModal}
                />
                <div className="general-tab-section">
                    <GeneralQuestions
                        project={project}
                        avttProjectName={avttProjectName}
                        iAmInterpreter={iAmInterpreter}
                    />
                </div>
                <ProfileAttributesView iAmInterpreter={iAmInterpreter} project={project} />
            </div>
        )
    }
)

const ProjectReviewTabs = observer(({ rt }: ProjectReviewProps) => {
    const [tabIndex, setTabIndex] = useReviewProjectTabIndex()
    const [showGeneralConfigModal, setShowGeneralConfigModal] = useState(false)
    const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false)
    const [isForcedToEnterProjectCode, setIsForcedToEnterProjectCode] = useState(false)

    const { reviewProject } = rt.project
    const { i18n, t } = useTranslation()

    useEffect(() => {
        rt.project.setUpProjectReview()
    }, [rt.project])

    const engageUrl = buildEngageUrl(rt.name, i18n.language)

    const createLinkToEngageProject = async () => {
        if (!engageAppUrl) {
            return
        }

        try {
            await navigator.clipboard.writeText(engageUrl)
            displayInfo(t('engageLinkCopied'))
        } catch (error) {
            displayError(error, t('Failed to copy!'))
        }
    }

    const handleSetPassage = async (recordingId: string) => {
        const portion = rt.project.findPortion(recordingId)
        const passage = rt.project.findPassage(recordingId)
        const passageVideo = passage?.findVideo(recordingId)

        if (passage && portion && passageVideo) {
            try {
                await rt.navigateToLinkLocation(portion, passage, passageVideo, rt.project.name)
            } catch (error) {
                displayError(error)
            }
        }
    }

    return (
        <div>
            <h3>{t('projectReviewTitle')}</h3>
            {showGeneralConfigModal && reviewProject && (
                <GeneralConfigModal
                    project={reviewProject}
                    closeModal={async () => {
                        setShowGeneralConfigModal(false)
                        if (!isForcedToEnterProjectCode) {
                            return
                        }

                        setIsForcedToEnterProjectCode(false)
                        if (reviewProject.isProjectCodeValid()) {
                            await reviewProject.setIsActive(true)
                        }
                    }}
                />
            )}
            {reviewProject && (
                <div className="review-project-header">
                    <Switch
                        enabled={rt.iAmAdmin}
                        value={reviewProject.isActive}
                        setValue={async (value) => {
                            if (value && !reviewProject.isProjectCodeValid()) {
                                setIsForcedToEnterProjectCode(true)
                                setShowGeneralConfigModal(true)
                                return
                            }
                            await reviewProject.setIsActive(value)
                        }}
                        className="review-project-toggle-switch"
                    >
                        {t('projectReviewAllow')}
                    </Switch>
                    {reviewProject.isActive && (
                        <GenericIconButton
                            iconName="fa-qrcode"
                            className="review-project-qrcode-modal-button"
                            enabled
                            onClick={() => setIsQRModalOpen(!isQRModalOpen)}
                            tooltip={t('engageQRCode')}
                        />
                    )}
                    {reviewProject.isActive && (
                        <span className="review-project-link-container">
                            <span className="review-project-link-text">{engageUrl}</span>
                            <ClipboardButton
                                buttonClassName=""
                                className="review-project-link-icon default-blue-icon"
                                enabled
                                onClick={createLinkToEngageProject}
                            />
                        </span>
                    )}
                </div>
            )}
            {isQRModalOpen && (
                <ProjectReviewModal setIsQRModalOpen={setIsQRModalOpen} url={engageUrl} title={reviewProject?.title} />
            )}
            {reviewProject && (
                <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
                    <TabList>
                        <Tab>
                            <GenericIcon
                                iconName="fa-cog"
                                className="project-review-button default-blue-icon"
                                tooltip={t('projectReviewGeneralConfigurationTitle')}
                            />
                        </Tab>
                        <Tab>
                            <GenericIcon
                                iconName="fa-list-ul"
                                className="project-review-button default-blue-icon"
                                tooltip={t('Passages')}
                            />
                        </Tab>
                        <Tab>
                            <GenericIcon
                                iconName="fa-comments"
                                className="project-review-button default-blue-icon"
                                tooltip={t('projectReviewResponses')}
                            />
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <ErrorBoundary>
                            <GeneralTab
                                iAmAdmin={rt.iAmAdmin}
                                iAmInterpreter={rt.iAmInterpreter}
                                project={reviewProject}
                                avttProjectName={rt.name}
                                setShowGeneralConfigModal={setShowGeneralConfigModal}
                            />
                        </ErrorBoundary>
                    </TabPanel>
                    <TabPanel>
                        <ErrorBoundary>
                            <EngagePassageList
                                projectName={rt.project.name}
                                reviewProject={reviewProject}
                                handleSetPassage={handleSetPassage}
                            />
                        </ErrorBoundary>
                    </TabPanel>
                    <TabPanel>
                        <ErrorBoundary>
                            <ReviewResponses rt={rt} reviewProject={reviewProject} />
                        </ErrorBoundary>
                    </TabPanel>
                </Tabs>
            )}
        </div>
    )
})

interface ProjectReviewProps {
    rt: Root
}

export const ProjectReview = observer(({ rt }: ProjectReviewProps) => {
    const { initialized, loadingMessage } = rt

    if (!initialized) {
        return <LoadingMessage {...{ loadingMessage }} />
    }

    return <ProjectReviewTabs rt={rt} />
})
