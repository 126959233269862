import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { EngageVerseTimeCode } from '../types'

export class ReviewProjectPassageRecording extends DBObject {
    @observable title = ''

    @observable editedTitle = ''

    @observable passageRecordingId = ''

    @observable fileName = ''

    @observable duration = 0

    @observable mimeType = ''

    @observable reference = ''

    @observable transcription = ''

    @observable timeCodes: EngageVerseTimeCode[] = []

    @observable isActive = true

    private static model = 22

    toDocument() {
        const {
            title,
            editedTitle,
            passageRecordingId,
            fileName,
            duration,
            mimeType,
            reference,
            transcription,
            isActive,
            rank,
            timeCodes
        } = this
        return this._toDocument({
            title,
            editedTitle,
            passageRecordingId,
            fileName,
            duration,
            mimeType,
            reference,
            transcription,
            isActive,
            rank,
            timeCodes,
            model: ReviewProjectPassageRecording.model
        })
    }

    async setIsActive(isActive: boolean) {
        if (isActive === this.isActive) {
            return
        }
        const doc = this.toDocument()
        doc.isActive = isActive
        await this.db.put(doc)
    }

    async setRank(rankNumber: number) {
        const rank = DBObject.numberToRank(rankNumber)
        if (rank === this.rank) {
            return
        }

        const doc = this.toDocument()
        doc.rank = rank
        await this.db.put(doc)
    }

    async setEditedTitle(newTitle: string) {
        const editedTitle = newTitle.trim()
        if (this.editedTitle === editedTitle) {
            return
        }

        const doc = this.toDocument()
        doc.editedTitle = editedTitle
        await this.db.put(doc)
    }
}
