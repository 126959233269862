import { FC, useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ReviewProjectPassageRecording } from '../../models3/ReviewProjectPassageRecording'
import { ModalFooter } from '../modals/Modals'
import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'
import { SimpleTextInput } from '../utils/TextInput'

interface Props {
    closeModal: () => void
    passage: ReviewProjectPassageRecording
}

export const EngagePassageTitleModal: FC<Props> = ({ closeModal, passage }) => {
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState('')
    const [engagedPassageTitle, setEngagedPassageTitle] = useState(
        passage.editedTitle ? passage.editedTitle : passage.title
    )

    const onSave = async () => {
        await passage.setEditedTitle(engagedPassageTitle)
        closeModal()
    }

    const handleValueValidation = (value: string) => {
        if (value.trim().length === 0) {
            return setErrorMessage(t('Empty name'))
        }

        return setErrorMessage('')
    }

    const handleSetValue = (value: string) => {
        setEngagedPassageTitle(value)
        handleValueValidation(value)
    }

    return (
        <Modal backdrop="static" onHide={closeModal} show>
            <Modal.Header closeButton>
                <Modal.Title>{`${t('passageTitle')}: ${passage.title}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LabeledFieldWithIcon
                    iconName="fa-tag"
                    title={t('engagePassageTitle')}
                    field={
                        <SimpleTextInput
                            value={engagedPassageTitle}
                            setValue={handleSetValue}
                            errorMessage={errorMessage}
                            autoFocus
                        />
                    }
                />
            </Modal.Body>
            <ModalFooter
                enabledOK={Boolean(!errorMessage.length)}
                enabledCancel
                onOK={onSave}
                onCancel={closeModal}
                okTooltip={t('OK')}
                cancelTooltip={t('Cancel')}
            />
        </Modal>
    )
}
