import { useState } from 'react'

import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { VisibleBiblesTable } from './ResourcesBibleTable'
import { Root } from '../../../models3/Root'
import { usePublishedBibles } from '../../app/PublishedBiblesContext'
import { CancelButton, OKButton } from '../../utils/Buttons'
import { GenericIcon } from '../../utils/Icons'

import '../ProjectSettings.css'

interface ResourcesSettingsModalProps {
    rt: Root
    closeModal: () => void
}

export const ResourcesSettingsModal = observer(({ closeModal }: ResourcesSettingsModalProps) => {
    const { t } = useTranslation()
    const { publishedBibles, visibleBibleIds: currentVisibleBibleIds, updateVisibleBibles } = usePublishedBibles()

    const [visibleBibleIds, setVisibleBibleIds] = useState(currentVisibleBibleIds)
    const [biblesFilter, setBiblesFilter] = useState('')
    const normalizedFilter = biblesFilter.trim().toLowerCase()
    const filteredBibles =
        normalizedFilter === ''
            ? publishedBibles
            : publishedBibles.filter(
                  ({ abbreviation, language, name }) =>
                      abbreviation.toLowerCase().includes(normalizedFilter) ||
                      language.name.toLowerCase().includes(normalizedFilter) ||
                      name.toLowerCase().includes(normalizedFilter)
              )

    const handleSwitch = (value: boolean, bibleId: string) => {
        if (value) {
            return setVisibleBibleIds((prev) => new Set(prev.add(bibleId)))
        }

        if (visibleBibleIds.size === 0) {
            const allBibleIds = new Set(publishedBibles.map((bible) => bible.id))
            allBibleIds.delete(bibleId)
            return setVisibleBibleIds(allBibleIds)
        }

        if (visibleBibleIds.size > 1) {
            return setVisibleBibleIds((prev) => {
                prev.delete(bibleId)
                return new Set(prev)
            })
        }

        return false // at least one Bible must remain visible
    }

    return (
        <Modal onHide={closeModal} show backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('showOrHideBibles')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="search-container">
                    <GenericIcon className="search-icon" iconName="fa-search" tooltip={t('search')} />
                    <input
                        className="bibles-table-search-input"
                        onChange={(e) => setBiblesFilter(e.target.value)}
                        type="text"
                        value={biblesFilter}
                    />
                </div>
                <VisibleBiblesTable
                    filteredBibles={filteredBibles}
                    visibleBibleIds={visibleBibleIds}
                    handleSwitch={handleSwitch}
                />
            </Modal.Body>
            <Modal.Footer className="flex-centered">
                <div className="button-row">
                    <OKButton
                        enabled
                        onClick={() => {
                            if (visibleBibleIds.size) {
                                updateVisibleBibles(visibleBibleIds)
                            }
                            closeModal()
                        }}
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                    <CancelButton enabled onClick={closeModal} className="cancel-button" tooltip={t('Cancel')} />
                </div>
            </Modal.Footer>
        </Modal>
    )
})
