import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useSyncStatus } from '../../hooks/useSyncStatus'
import { Root } from '../../models3/Root'
import { SlttHelp } from '../utils/Buttons'

export const SyncStatus = ({ rt }: { rt: Root }) => {
    const { t } = useTranslation()

    const { loading, firstTimeLoading, vcrsToDisplay } = useSyncStatus(rt)

    if ((loading && firstTimeLoading) || vcrsToDisplay.length === 0) {
        return null
    }

    const offlineMessageId = 'sign-out-info'

    return (
        <SlttHelp id={offlineMessageId} tooltip={t('filesNotUploaded')} place="bottom">
            <Link to="/project-settings?tab=upload-report" className="app-selector-icon">
                <i className={classNames('sync-status-icon', 'fa-cloud-upload-alt', 'fas')} />
            </Link>
        </SlttHelp>
    )
}
