import { Mp3Encoder } from 'lamejs'

import { getWavDataWithoutHeader } from './Wav'
import { MimeType } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('AudioProcessing:Lame')

const DEFAULT_BIT_RATE = 96

const DEFAULT_SAMPLE_RATE = 44100

const DEFAULT_SAMPLE_BLOCK_SIZE = 1152 // can be anything but make it a multiple of 576 to make encoders life easier

const convertBufferToInt16Array = (arrayBuffer: ArrayBuffer): Int16Array => {
    const input = new Float32Array(arrayBuffer)
    const output = new Int16Array(input)

    for (let i = 0; i < input.length; i++) {
        const s = Math.max(-1, Math.min(1, input[i]))
        output[i] = s < 0 ? s * 0x8000 : s * 0x7fff
    }

    return output
}

export const encodeMp3 = async (input: Blob | File): Promise<File> => {
    log(`Starting mp3 encoding`)

    const arrayBuffer = await getWavDataWithoutHeader(input)
    const audioData = convertBufferToInt16Array(arrayBuffer)
    const channels = 1 // single channel only

    const dataBuffer: Int8Array[] = []
    let remaining = audioData.length
    const encoder = new Mp3Encoder(channels, DEFAULT_SAMPLE_RATE, DEFAULT_BIT_RATE)
    for (let i = 0; remaining >= 0; i += DEFAULT_SAMPLE_BLOCK_SIZE) {
        const mono = audioData.subarray(i, i + DEFAULT_SAMPLE_BLOCK_SIZE)
        const buffer = encoder.encodeBuffer(mono)
        dataBuffer.push(new Int8Array(buffer))
        remaining -= DEFAULT_SAMPLE_BLOCK_SIZE
    }
    dataBuffer.push(new Int8Array(encoder.flush()))

    const mp3Blob = new Blob(dataBuffer, { type: MimeType.MPEG })

    log(`Completed mp3 encoding`)

    return new File([mp3Blob], 'output.mp3', { type: mp3Blob.type })
}
