import { useEffect, useState } from 'react'

import _ from 'underscore'

import { Root } from '../models3/Root'
import { VideoCache } from '../models3/VideoCache'
import { VideoCacheRecord } from '../models3/VideoCacheRecord'

const getUploadVcrs = async () => {
    // first uploads that have not yet started then most recent uploads first
    const sortKey = (vcr: VideoCacheRecord) => (vcr.uploadStartTimeMs > 0 ? -vcr.uploadStartTimeMs : -9999999999999)

    let vcrs = await VideoCache.getAllVcrs()
    vcrs = vcrs.filter((vcr) => vcr.uploadeds.length > 0)
    vcrs = _.sortBy(vcrs, sortKey)
    return vcrs
}

export const useSyncStatus = (rt: Root) => {
    const [vcrs, setVcrs] = useState<VideoCacheRecord[]>([])
    const [loading, setLoading] = useState(true)
    const [firstTimeLoading, setFirstTimeLoading] = useState(true)

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null

        async function getAllVcrs() {
            if (!vcrs.length) {
                setLoading(true)
            }

            const _vcrs = await getUploadVcrs()
            setVcrs(_vcrs)
            setLoading(false)
            setFirstTimeLoading(false)
            timer = setTimeout(getAllVcrs, 15000)
        }

        getAllVcrs()

        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [vcrs.length])

    const vcrsToDisplay = vcrs.filter((vcr) => vcr.needsUploaded).filter((vcr) => vcr.projectName() === rt.project.name)

    return {
        loading,
        firstTimeLoading,
        vcrsToDisplay
    }
}
