import { FC, useState, useEffect, useMemo } from 'react'

import { observer } from 'mobx-react'
import { Col, Row, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import ProjectActivityInfo from './ProjectActivityInfo'
import ProjectProfiles from './ProjectProfiles'
import ProjectRecordingInfo from './ProjectRecordingInfo'
import { ProjectsEditorCSVExportButton } from './ProjectsEditorCSVExportButton'
import useFetchLanguageTags from '../../hooks/useFetchLanguageTags'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { languageToOption } from '../../resources/LanguageTags'
import { useOnlineStatus } from '../app/OnlineStatusContext'
import { useAppRoot } from '../app/RootContext'
import { ProjectAddModal } from '../modals/project/AddModal'
import { ProjectDeleteModal } from '../modals/project/DeleteModal'
import { ProjectProfilesModal } from '../modals/project/ProjectProfilesModal'
import MembersByRoleView from '../projectSettings/members/MembersByRoleView'
import { LanguageSelector } from '../projectSettings/preferences/LanguageSelector'
import { SelectCreatableOption } from '../select/SelectCreatable'
import { AddButton, DeleteButton, EditButton } from '../utils/Buttons'
import { getModByFromDoc } from '../utils/Helpers'
import { GenericIcon, LoadingIcon } from '../utils/Icons'

import './ProjectsEditor.css'

interface ProjectsEditorProps {
    groupRoot?: Root
}

const extractUniqueOptions = (projects: Root[], attribute: string): { value: string; label: string }[] => {
    const uniqueValues = new Set<string>()
    projects.forEach((rt) => {
        if (rt.project.projectProfiles?.[attribute]) {
            uniqueValues.add(rt.project.projectProfiles[attribute] as string)
        }
    })
    return Array.from(uniqueValues).map((value) => ({ value, label: value }))
}

const ProjectsEditor: FC<ProjectsEditorProps> = observer(({ groupRoot }) => {
    const { getAllProjectRootsSorted, iAmRoot, iAmRootObserver, rts, sortRootProjects } = useAppRoot()
    const { t } = useTranslation()
    const { isOnline } = useOnlineStatus()
    const { languageTags, isLoading: isLoadingLanguageTags } = useFetchLanguageTags()

    const [isProjectAddModalOpen, setIsProjectAddModalOpen] = useState(false)
    const [openProjectDeleteModal, setOpenProjectDeleteModal] = useState<Root>()
    const [openProjectProfilesModal, setOpenProjectProfilesModal] = useState<Project>()

    const [projectSearchQuery, setProjectSearchQuery] = useState<string>('')

    const [statusOptions, setStatusOptions] = useState<SelectCreatableOption[]>([])
    const [partnerOptions, setPartnerOptions] = useState<SelectCreatableOption[]>([])
    const [countryOptions, setCountryOptions] = useState<SelectCreatableOption[]>([])

    const isGroupAdmin = groupRoot?.iAmAdmin
    const isAbleToSeeProfiles = iAmRoot || iAmRootObserver

    const languageTagsMap = useMemo(() => {
        const map = new Map<string, string>()
        languageTags.forEach((tag) => {
            const option = languageToOption(tag)
            map.set(option.value, option.label.split('[')[0].trim())
        })
        return map
    }, [languageTags])

    const allProjects = useMemo(() => {
        const lowerCaseQuery = projectSearchQuery.toLowerCase()

        const projects = groupRoot
            ? rts.filter(({ project }) => groupRoot.groupProjects?.includes(project.name)).sort(sortRootProjects)
            : getAllProjectRootsSorted()

        return projects.filter(
            ({ project: { language, projectActivityInfo, projectProfiles, getFormattedDisplayName } }) => {
                const languageFullname = languageTagsMap.get(language) || ''
                const firstModBy = getModByFromDoc(projectActivityInfo?.firstDoc)
                const lastModBy = getModByFromDoc(projectActivityInfo?.lastDoc)
                const fieldsToSearch = [getFormattedDisplayName(), language, languageFullname, firstModBy, lastModBy]

                if (isAbleToSeeProfiles) {
                    const { status = '', partner = '', country = '', notes = '' } = projectProfiles || {}
                    fieldsToSearch.push(status, partner, country, notes)
                }

                return fieldsToSearch.some((field) => field?.toLowerCase().includes(lowerCaseQuery))
            }
        )
    }, [
        groupRoot,
        rts,
        sortRootProjects,
        getAllProjectRootsSorted,
        projectSearchQuery,
        languageTagsMap,
        isAbleToSeeProfiles
    ])

    useEffect(() => {
        setStatusOptions(extractUniqueOptions(allProjects, 'status'))
        setPartnerOptions(extractUniqueOptions(allProjects, 'partner'))
        setCountryOptions(extractUniqueOptions(allProjects, 'country'))
    }, [allProjects])

    useEffect(() => {
        // do not initialize if we are displaying all projects
        if (groupRoot) {
            allProjects.forEach((rt) => rt.initialize())
        }
    }, [allProjects, groupRoot])

    const isLoadingProjects = groupRoot ? allProjects.some((rt) => !rt.initialized) : false
    if (isLoadingProjects || isLoadingLanguageTags) {
        return <LoadingIcon className="" />
    }

    const reloadPage = () => {
        // This only works sometimes when not in a timeout. My guess is there is some
        // kind of race condition.
        setTimeout(() => {
            window.location.reload()
        }, 0)
    }

    const handleLanguageChange = (rt: Root, newLanguage: string) => {
        // create a project doc entry
        rt.project.setLanguage(newLanguage)

        // update the project attributes so the result can be seen without a refresh
        rt.project.projectProfiles = {
            ...rt.project.projectProfiles,
            language: newLanguage
        }
    }

    const handleProfilesUpdate = () => {
        setStatusOptions(extractUniqueOptions(allProjects, 'status'))
        setPartnerOptions(extractUniqueOptions(allProjects, 'partner'))
        setCountryOptions(extractUniqueOptions(allProjects, 'country'))
    }

    return (
        <div>
            {openProjectProfilesModal && (
                <ProjectProfilesModal
                    project={openProjectProfilesModal}
                    statusOptions={statusOptions}
                    partnerOptions={partnerOptions}
                    countryOptions={countryOptions}
                    onProfilesUpdate={handleProfilesUpdate}
                    closeModal={() => setOpenProjectProfilesModal(undefined)}
                />
            )}
            {openProjectDeleteModal && (
                <ProjectDeleteModal closeModal={reloadPage} projectRoot={openProjectDeleteModal} />
            )}
            {isProjectAddModalOpen && <ProjectAddModal closeModal={reloadPage} groupRoot={groupRoot} />}
            <div className="container-fluid">
                <div className="project-heading-row">
                    <h3>{groupRoot ? t('Group Projects') : t('myProjectsHeader')}</h3>
                </div>
                <div className="row">
                    <div className="col-lg-auto">
                        <div className="search-container">
                            <GenericIcon className="search-icon" iconName="fa-search" tooltip={t('search')} />
                            <input
                                onChange={(e) => setProjectSearchQuery(e.target.value)}
                                type="text"
                                value={projectSearchQuery}
                            />
                            <ProjectsEditorCSVExportButton languageTagsMap={languageTagsMap} groupRoot={groupRoot} />
                        </div>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>{t('Project')}</th>
                                    <th>{t('projectLanguage')}</th>
                                    <th>{t('projectActivity')}</th>
                                    <th>{t('passageRecording')}</th>
                                    {groupRoot && <th>{t('Admin Users')}</th>}
                                    {isAbleToSeeProfiles && <th>{t('projectProfilesLabel')}</th>}
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {allProjects.map((rt) => (
                                    <tr key={rt.project.name}>
                                        <td>
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                {groupRoot
                                                    ? rt.project.getFormattedDisplayName()
                                                    : `${
                                                          rt.group ? `${rt.group}\n  ` : ''
                                                      }${rt.project.getFormattedDisplayName()}`}
                                            </div>
                                        </td>
                                        <td>
                                            <LanguageSelector
                                                classNamePrefix="project-language-selector"
                                                languageCode={rt.project.language}
                                                setLanguageCode={(newLanguage) => handleLanguageChange(rt, newLanguage)}
                                                languageTags={languageTags}
                                                selectStyle="auto"
                                                enabled={rt.iAmAdmin}
                                            />
                                        </td>
                                        <td>
                                            <ProjectActivityInfo project={rt.project} />
                                        </td>
                                        <td>
                                            <ProjectRecordingInfo project={rt.project} />
                                        </td>
                                        {groupRoot && (
                                            <td>
                                                <MembersByRoleView project={rt.project} includeRoles={['admin']} />
                                            </td>
                                        )}
                                        {isAbleToSeeProfiles && (
                                            <td>
                                                <ProjectProfiles project={rt.project} />
                                            </td>
                                        )}
                                        {iAmRoot && (
                                            <td>
                                                <Row className="no-gutters">
                                                    <Col>
                                                        <EditButton
                                                            onClick={() => setOpenProjectProfilesModal(rt.project)}
                                                            className="sl-delete-button"
                                                            tooltip={t('Edit')}
                                                            enabled={isOnline}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <DeleteButton
                                                            onClick={() => setOpenProjectDeleteModal(rt)}
                                                            className="sl-delete-button"
                                                            tooltip={t('Delete Project')}
                                                            enabled={isOnline}
                                                        />
                                                    </Col>
                                                </Row>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {(iAmRoot || isGroupAdmin) && (
                            <AddButton
                                onClick={() => setIsProjectAddModalOpen(true)}
                                className="project-plan-copy-button"
                                buttonClassName=""
                                tooltip={t('Add project')}
                                enabled={isOnline}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ProjectsEditor
