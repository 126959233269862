import { useTranslation } from 'react-i18next'

import { findMostRecentVideoBeforeDate, findStatusAtDate, getPercentCompleteThroughPlan } from './ProgressUtils'
import { Portion } from '../../../models3/Portion'
import { Root } from '../../../models3/Root'
import { RefRange } from '../../../resources/RefRange'
import { DownloadButton } from '../../utils/Buttons'
import { exportToFile } from '../../utils/Helpers'

interface ProgressReportExportButtonProps {
    beginDate: Date
    endDate: Date
    rt: Root
    groupRoots: Root[]
}

// By using a tab separator, we don't have to deal with commas being in the data
const SEPARATOR = `\t`

export const ProgressReportExportButton = ({ beginDate, endDate, rt, groupRoots }: ProgressReportExportButtonProps) => {
    const { t } = useTranslation()
    const { plans, versification } = rt.project

    if (!plans.length) {
        return null
    }

    const plan = plans[0]

    const getRowsForPortion = (portion: Portion, projectRoot: Root) => {
        const { passages } = portion
        const shouldShow = passages.some((passage) => passage.difficulty > 0)
        if (!shouldShow) {
            return ''
        }

        // One row per passage
        const passageRows = passages.map((passage) => {
            const getProgressAtDate = (date: Date) => {
                const video = findMostRecentVideoBeforeDate(passage.videosNotDeleted, date)
                const statusAtDate = video ? findStatusAtDate(video, date) : ''
                const task = video ? plan.tasks.find((planTask) => planTask.id === statusAtDate) : null
                const stage = task ? plan.stages.find((planStage) => planStage.tasks.includes(task)) : null
                const stageName = stage?.displayedName ?? ''
                const taskName = task?.displayedName ?? ''
                const percentCompletion = Math.round(getPercentCompleteThroughPlan([passage], plan, date) * 100)
                return [stageName, taskName, percentCompletion]
            }

            return [
                projectRoot.group ?? '',
                projectRoot.project.getFormattedDisplayName(),
                portion.name,
                passage.name,
                rt.displayableReferences(passage.references),
                RefRange.getAllVersesInRefRanges(passage.references, versification).length,
                ...getProgressAtDate(beginDate),
                ...getProgressAtDate(endDate)
            ].join(SEPARATOR)
        })
        return `${passageRows.join('\n')}\n`
    }

    const getCsvBody = () => {
        return groupRoots
            .flatMap((root) => root.project.portions.map((portion) => getRowsForPortion(portion, root)))
            .join('')
    }

    const getCsvHeader = () => {
        const getProgressColumns = (date: Date) => {
            const formattedDate = rt.dateFormatter.format(date)
            return [
                t('projectPlanStageAtDate', { dateString: formattedDate }),
                t('projectPlanTaskAtDate', { dateString: formattedDate }),
                t('projectPlanPercentCompletedAsOfDate', { dateString: formattedDate })
            ]
        }

        const columns = [
            t('Group'),
            t('Project'),
            t('Portion'),
            t('Passage'),
            t('References'),
            t('Verse Count'),
            ...getProgressColumns(beginDate),
            ...getProgressColumns(endDate)
        ]
        return columns.join(SEPARATOR)
    }

    const getCsvContents = () => {
        return `${getCsvHeader()}\n${getCsvBody()}`
    }

    return (
        <DownloadButton
            className="csv-export-button"
            enabled
            onClick={() => exportToFile(getCsvContents(), 'progress-report', '.csv')}
            tooltip={t('exportAsCSV')}
        />
    )
}
