import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SortableItem } from './SortableItem'
import { PencilButton } from '../utils/Buttons'

type EngagePassageSortableItemProps = {
    id: string
    name: string
    editedName: string
    date: string
    openModal: (id: string) => void
    link: string
    onClick: () => void
    versionLabel: string | number
}

export const EngagePassageSortableItem = observer(
    ({ id, name, editedName, date, link, onClick, openModal, versionLabel }: EngagePassageSortableItemProps) => {
        const { t } = useTranslation()

        const getDisplayName = () => {
            if (!editedName || editedName === name) {
                return name
            }
            return `${editedName} (${name})`
        }

        const displayName = getDisplayName()

        return (
            <SortableItem id={id} className="general-question-sortable-container-item" enabled>
                <span className="general-question-title">{displayName}</span>
                <PencilButton
                    enabled
                    onClick={() => openModal(id)}
                    className="project-review-button default-blue-icon general-question-edit-button"
                    tooltip={t('Edit')}
                />
                <span>
                    <Link onClick={onClick} to={link}>
                        {versionLabel} {date}
                    </Link>
                </span>
            </SortableItem>
        )
    }
)
