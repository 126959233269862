import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Root } from '../../../models3/Root'
import { SortableItem } from '../../projectReview/SortableItem'
import { SegmentEditorPanel } from '../../segments/SegmentPanelOrder'
import { Switch } from '../../utils/Switch'

export const RecordingLayoutSortableItem = ({ rt, id }: { rt: Root; id: SegmentEditorPanel }) => {
    const { t } = useTranslation()
    const [visible, setVisible] = useState(!rt.project.segmentEditorHiddenPanels.includes(id))

    return (
        <SortableItem id={id} className="sortable-container-item" enabled>
            <span className={visible ? '' : 'hidden-item'}>{t(id)}</span>
            <Switch
                value={visible}
                setValue={async (value) => {
                    setVisible(value)
                    await rt.project.setSegmentEditorPanelVisibility(id, value)
                }}
                tooltip={t('showOrHide')}
                className="sortable-container-item-switch"
            />
        </SortableItem>
    )
}
