import * as P from 'parsimmon'

import { languageBookNameParser, projectBookNameParser } from './bookNames'
import { nnn } from '../components/utils/Helpers'

const hyphenSep = P.regex(/ *- */)
const semiSep = P.regex(/ *; */)
const commaSep = P.regex(/ *, */)
const chapterVerseSep = P.regex(/ *[.:] */)
const bookChapterSep = P.regex(/ +/)

const verseParser = P.regexp(/\d+[abc]?/).map((s) => {
    s = s.replace(/[abc]/, '')
    return nnn(parseInt(s))
})

const chapterParser = P.regexp(/0*[1-9]+[0-9]*/).map((s) => nnn(parseInt(s)))

const versesParser = P.alt(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    P.seq(verseParser, hyphenSep, verseParser).map(([verse1, separator, verse2]) => `${verse1}-${verse2}`),
    verseParser.map((verse) => `${verse}-${verse}`)
)

const versesListParser = versesParser.sepBy1(commaSep)

const multiChapterWithVersesParser = P.seq(
    chapterParser,
    chapterVerseSep,
    verseParser,
    hyphenSep,
    chapterParser,
    chapterVerseSep,
    verseParser
).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([c1, cvSep1, v1, cvSep2, c2, cvSep3, v2]) => [`${c1}${v1}-${c2}${v2}`]
)

const singleChapterWithVersesParser = P.seq(chapterParser, chapterVerseSep, versesListParser).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([chapter, _unused, vrs]) =>
        vrs.map((vr) => {
            const parts = vr.split('-')
            return `${chapter}${parts[0]}-${chapter}${parts[1]}`
        })
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const multiChapterParser = P.seq(chapterParser, hyphenSep, chapterParser).map(([chapter1, separator, chapter2]) => [
    `${chapter1}-${chapter2}`
])

const singleChapterParser = chapterParser.map((chapter) => [`${chapter}-${chapter}`])

const chapterVerseParser = P.alt(
    multiChapterWithVersesParser,
    singleChapterWithVersesParser,
    multiChapterParser,
    singleChapterParser
)

const singleChapterSuggestionParser = P.seq(chapterParser, chapterVerseSep).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([c1, cvSep1]) => [c1]
)

const multiChapterSuggestionParser = P.seq(
    chapterParser,
    chapterVerseSep,
    verseParser,
    hyphenSep,
    chapterParser,
    chapterVerseSep
).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([c1, cvSep1, v1, hs, c2, cvSep2]) => [`${c1}${v1}-${c2}`]
)

const chapterVerseSuggestionParser = P.alt(multiChapterSuggestionParser, singleChapterSuggestionParser)

const chapterVerseListParser = chapterVerseParser.sepBy1(semiSep).map((cvs) => cvs.flat())

const bookParser = (uiLanguageCode: string, projectBookNames: string[]) =>
    P.alt(projectBookNameParser(projectBookNames), languageBookNameParser(uiLanguageCode), languageBookNameParser('en'))

const bookWithChapterVerseParser = (uiLanguageCode: string, projectBookNames: string[]) =>
    P.seq(bookParser(uiLanguageCode, projectBookNames), bookChapterSep, chapterVerseListParser).map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([book, separator, cvs]) =>
            cvs.map((cv) => {
                const parts = cv.split('-')
                const bbb = nnn(book)
                return `${bbb}${parts[0]}-${bbb}${parts[1]}`
            })
    )

const justBookParser = (uiLanguageCode: string, projectBookNames: string[]) =>
    P.seq(bookParser(uiLanguageCode, projectBookNames)).map(([book]) => {
        const bbb = nnn(book)
        return [`${bbb}`]
    })

const bookWithChapterVerseSuggestionParser = (uiLanguageCode: string, projectBookNames: string[]) =>
    P.seq(bookParser(uiLanguageCode, projectBookNames), bookChapterSep, chapterVerseSuggestionParser).map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([book, separator, cvs]) =>
            cvs.map((cv) => {
                const parts = cv.split('-')
                const bbb = nnn(book)
                if (parts.length === 1) {
                    return `${bbb}${parts[0]}`
                }
                return `${bbb}${parts[0]}-${bbb}${parts[1]}`
            })
    )

const referencePartsParser = (uiLanguageCode: string, projectBookNames: string[]) =>
    P.alt(
        bookWithChapterVerseParser(uiLanguageCode, projectBookNames),
        justBookParser(uiLanguageCode, projectBookNames)
    )

const suggestionPartsParser = (uiLanguageCode: string, projectBookNames: string[]) =>
    P.alt(
        bookWithChapterVerseSuggestionParser(uiLanguageCode, projectBookNames),
        justBookParser(uiLanguageCode, projectBookNames)
    )

export const referenceParser = (uiLanguageCode: string, projectBookNames: string[]) =>
    referencePartsParser(uiLanguageCode, projectBookNames)
        .sepBy1(semiSep)
        .map((cvs) => cvs.flat())

export const referenceSuggestionParser = (uiLanguageCode: string, projectBookNames: string[]) =>
    suggestionPartsParser(uiLanguageCode, projectBookNames).map((cvs) => cvs.join(''))

export enum ReferenceParseError {
    INVALID_BOOK_NAME = 'INVALID_BOOK_NAME',
    GENERIC = 'REFERENCE_PARSE_ERROR_GENERIC'
}

export const getReferenceParseError = (error: Error) => {
    if (!error.message.includes('PARSING FAILED')) {
        return
    }
    if (error.message.includes('*Invalid book name')) {
        return ReferenceParseError.INVALID_BOOK_NAME
    }
    return ReferenceParseError.GENERIC
}
